import { ContactType } from "../contacts/type";
import { SchedulingMethod } from "../scheduling-methods/type";

export const PHONE_PATTERN = /^(?=.{8,25}$)((\+)*\d+( )*)*(\(\d{1,3}\)( )*)*(\d+)*(\d+[ \-]\d+)*(\d+)$/

export const detectContactType = (contact) => {
  if (contact.includes('@'))
    return ContactType.EMAIL;
  else if (PHONE_PATTERN.test(contact))
    return ContactType.PHONE;
  else
    return ContactType.PORTAL;
}

export const acceptableContactType = (schedMethod: SchedulingMethod): ContactType => {
  switch(schedMethod) {
    case SchedulingMethod.EMAIL:
    case SchedulingMethod.FCFS_NOTIFY_EMAIL:
      return ContactType.EMAIL;
    case SchedulingMethod.PHONE:
    case SchedulingMethod.FCFS_NOTIFY_PHONE:
      return ContactType.PHONE;
    case SchedulingMethod.PORTAL:
      return ContactType.PORTAL;
  } 
}

export const getContactIcon = (contactType) => {
  switch (contactType) {
    case ContactType.PORTAL:
      return "bi bi-link-45deg";
    case ContactType.PHONE:
      return "bi bi-telephone";
    default:
      return "bi bi-envelope-at";
  }
}

export const getContactInputType = (schedMethod: SchedulingMethod) : string => {
  switch (schedMethod) {
    case SchedulingMethod.PHONE:
    case SchedulingMethod.FCFS_NOTIFY_PHONE:
      return "tel";
    case SchedulingMethod.PORTAL:
      return "text";
    default:
      return "email";
  }
};