import moment from "moment-timezone";

export const getShortDateTime = (date: Date, timezone: string) => {
  return date.toLocaleString('sv', { timeZone: timezone,
      hour12: false, dateStyle: 'short', timeStyle: 'short'}).substring(5).replace('-', '/');
}

export const secondsToTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const getDayOfWeek = (day: number) => {
  return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][day];
}

export const getScheduledDateTime = (time: Date, timezone: string, isDate: boolean) => {
  let value = time.toLocaleString('sv', { timeZone: timezone,
    hour12: false, dateStyle: 'short', timeStyle: 'short'});
  if (isDate)
    value = value.split(' ')[0];
  else
    value = value.split(' ')[1];
  return value;
};

export const setScheduledDateTime = (time: Date, timezone: string, value: string, isDate: boolean) => {
  let fullValue = time.toLocaleString('sv',
    { timeZone: timezone, hour12: false, dateStyle: 'short', timeStyle: 'short'})
  if (isDate)
    fullValue = `${value} ${fullValue.split(' ')[1]}`;
  else
    fullValue = `${fullValue.split(' ')[0]} ${value}`;
  return new Date(moment.tz(fullValue, timezone));
};

export const formatDateTime = (date: Date) =>
  date ? date.toLocaleString('en-us', { hour12: true, dateStyle: 'short', timeStyle: 'short'}) : null;

export const timezones = {
  "US/Eastern": "ET",
  "US/Central": "CT",
  "US/Mountain": "MT",
  "US/Pacific": "PT",
  "US/Alaska": "AKT",
  "US/Hawaii": "HT"
};