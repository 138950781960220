import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, InputGroup, Modal, Spinner, Stack, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import Paging, { PagingMeta } from "@/components/layout/paging";
import { Resource } from "../type";
import useRailsToast from "@/components/utils/use-rails-toast";
import { IconTrash } from "@tabler/icons-react";

const Search = () => {
  const { getToken, userId, isLoaded } = useAuth();
  const railsToast = useRailsToast();
  const [resource, setResource] = useState<Resource>({} as Resource);
  const [resources, setResources] = useState<Resource[]>([]);
  const [meta, setMeta] = useState<PagingMeta>({ page: 1, perPage: 10, totalCount: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const navigate = useNavigate();
  
  const fetchResources = async (pgMeta: PagingMeta) => {
    setLoading(true);
    const accessToken = await getToken();
    let path = search?.length > 0 ? `search=${search}&` : '';
    const response = await fetch(
      `/api/v1/resources?${path}page=${pgMeta.page}&per_page=${pgMeta.perPage}${
        pgMeta.sortBy ? `&sort_by=${pgMeta.sortBy}&sort=${pgMeta.sort}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const json = await response.json();
    const resStatus = response.status;
    if ([200, 304].includes(resStatus)) {
      setResources(json.items.map(f => ({ ...f, createdAt: new Date(f.createdAt) })));
      setMeta(json.meta);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const addResource = async (e) => {
    e.preventDefault();
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/resources/`,
      {
        method: "POST",
        body: JSON.stringify({ resource: { name: resource.name, url: resource.url }}),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const status = response.status;
    if ([200, 201, 304].includes(status)) {
      setResource({} as Resource);
      fetchResources(meta); 
      railsToast({ info: 'Resource has been added' }, status);
    } else {
      railsToast(await response.json(), status);
    }
    setShow(false);
    setLoading(false);
  }
  
  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/")
    }
    fetchResources(meta); 
  }, [search]);

  useEffect(() => {
    if (resource.id) {
      setShowConfirm(true);
    }
  }, [resource]);

  const inputChanged = (e) => {
    resource[e.target.formTarget] = e.target.value;
  };

  const cancel = () => {
    setResource({} as Resource);
    setShow(false);
    setShowConfirm(false);
  };

  const deleteResource = async () => {
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/resources/${resource.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const status = response.status;
    if ([200, 204].includes(status)) {
      setResource({} as Resource);
      fetchResources(meta); 
      railsToast({ info: 'Resource has been deleted' }, status);
    } else {
      railsToast(await response.json(), status);
    }
    setShow(false);
    setLoading(false);
    cancel();
  }

  return (
    <>
      <h1 className="h2 text-capitalize">Resources</h1>
      <div className="py-2">
        <InputGroup>
          <Form.Control type="text" placeholder="Search by name, or url" value={search}
            onChange={(e) => setSearch(e.currentTarget.value)} />
          <Button variant="primary" onClick={() => setShow(true)}>+</Button>
        </InputGroup>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Form onSubmit={addResource}>
          <Modal.Header closeButton>
            <Modal.Title>Add new resource</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" value={resource.name} placeholder="name"
                formTarget="name" required onChange={inputChanged} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control type="url" value={resource.url} placeholder="https://..."
                formTarget="url" required onChange={inputChanged} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={cancel}>No</Button>
            <Button type="submit">Yes</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete {resource.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to delete {resource.name}, are you sure about that?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={cancel}>No</Button>
          <Button onClick={deleteResource}>Yes</Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : resources.length > 0 ? (
        <>
          <Stack direction="horizontal" className="pt-2 justify-content-between">
            <div></div>
            <Paging meta={meta} onPaging={fetchResources}></Paging>
          </Stack>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Link</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {resources.map((resource) => (
                <tr key={resource.id} className="align-middle">
                  <td>{resource.name}</td>
                  <td><a href={resource.url}>{resource.url}</a></td>
                  <td>
                    <a onClick={() => setResource(resource)} role="button"><IconTrash/></a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="text-center">
          <p>Type a name or url above to perform a search.</p>
        </div>
      )}
    </>
  );
};

export default Search;