import { useEffect, useState } from "react";
import { TaskUpdate } from "../types";
import { Portal } from "@/components/contacts/type";

const ContactUpdate = ({ taskUpdate } : { taskUpdate: TaskUpdate }) => {
  const [portals, setPortals] = useState<Portal[]>();

  useEffect(() => {
    if (taskUpdate.value1.startsWith('updated')) {
      setPortals(JSON.parse(taskUpdate.value2));
    } else {
      setPortals([JSON.parse(taskUpdate.value2)]);
    }
  }, []);

  const updatedFields = () : string => {
    let fields = [];
    if (portals?.length === 2) {
      for (const field of [...Object.keys(portals[0]), ...Object.keys(portals[1])]) {
        if (!fields.includes(field) && portals[0][field] !== portals[1][field]) {
          fields.push(field);
        }
      }
    }
    return fields.join(', ');
  };

  return (
    <>
      {!taskUpdate.value1.startsWith('updated') ? <span>{portals?.[0]?.name}</span>:
      <>
        <span>{updatedFields()}</span>
        <del>{portals?.[0]?.name}</del>
        <b>{portals?.[1]?.name}</b>
      </>
      }
    </>
  );
};

export default ContactUpdate;