import useRailsToast from "@/components/utils/use-rails-toast";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const SessionManager = () => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const [sessionId, setSessionId] = useState<string>(localStorage.getItem('bSessionId'));
  const [iframeUrl, setIframeUrl] = useState<string>();

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem('bSessionId', sessionId);
      getSession();
    }
  }, [sessionId]);

  const getSession = async () => {
    if (sessionId && !iframeUrl) {
      const accessToken = await getToken();
      const response = await fetch(`/api/v1/sessions/${sessionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setIframeUrl((await response.json()).url);  
      } else {
        localStorage.removeItem('bSessionId');
        setSessionId(null);
      }
    }
  }

  const startSession = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/sessions/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    if (response.ok) {
      setIframeUrl(data.url);
      setSessionId(data.sessionId);
    } else {
      railsToast(data, response.status);
    }
  };

  const stopSession = async () => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/sessions/${sessionId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      localStorage.removeItem('bSessionId');
      setIframeUrl(null);
      setSessionId(null);
    } else {
      railsToast(await response.json(), response.status);
    }
  };

  return (
    <div>
      <Button onClick={!sessionId ? startSession : stopSession}>
        {!sessionId ? 'Start' : 'Stop'} Session
      </Button>
      {iframeUrl && (
        <iframe
          src={iframeUrl}
          sandbox="allow-same-origin allow-scripts"
          allow="clipboard-read; clipboard-write"
          style={{ width: '100%', height: '500px', border: 'none' }}
        />
      )}
    </div>
  );
};

export default SessionManager;