import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Contact } from "@/components/contacts/type";
import { SchedulingMethod } from "../type";
import { acceptableContactType } from "../../utils/contact-utils";

const SchedulingMethodInput = ({ schedMethod, contacts, onUpdate } :
  { schedMethod: SchedulingMethod, contacts: Contact[], onUpdate: (sm: SchedulingMethod) => Promise<boolean> }) => {
  const [oldSchedMethod, setOldSchedMethod] = useState<SchedulingMethod>(schedMethod);
  const [schedulingMethod, setSchedulingMethod] = useState<SchedulingMethod>(schedMethod);
  const [show, setShow] = useState(false);

  const updating = (sm: SchedulingMethod) => {
    if (contacts?.length > 0 && acceptableContactType(sm) !== contacts[0].contactType) {
      setShow(true);
    } else {
      setSchedulingMethod(sm);
    }
  };

  useEffect(() => {
    if (schedulingMethod !== oldSchedMethod) {
      if (acceptableContactType(schedulingMethod) === acceptableContactType(oldSchedMethod)) {
        update();
      } else {
        setShow(true);
      }
    }
  }, [schedulingMethod]);

  const update = async () => {
    setShow(false);
    if (await onUpdate(schedulingMethod)) {
      setOldSchedMethod(schedulingMethod);
    } else {
      setSchedulingMethod(oldSchedMethod);
    }
  };

  const cancel = () => {
    setSchedulingMethod(oldSchedMethod);
    setShow(false);
  };

  return (
    <>
      <Form.Select value={schedulingMethod} style={{width: '200px'}}
        onChange={async (e) => setSchedulingMethod(e.target.value as SchedulingMethod)}>
        <option value={SchedulingMethod.PORTAL}>Portal</option>
        <option value={SchedulingMethod.EMAIL}>Email</option>
        <option value={SchedulingMethod.FCFS}>FCFS</option>
        <option value={SchedulingMethod.FCFS_NOTIFY_EMAIL}>FCFS - Notify Email</option>
        <option value={SchedulingMethod.FCFS_NOTIFY_PHONE}>FCFS - Notify Phone</option>
        <option value={SchedulingMethod.PHONE}>Phone</option>
      </Form.Select>
      <Modal show={show} onHide={cancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change scheduling method?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Changing schedule method will remove all contacts, do you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={cancel}>No</Button>
          <Button onClick={update}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SchedulingMethodInput;