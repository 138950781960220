import {
  OrganizationProfile,
  OrganizationSwitcher,
  useOrganization,
} from "@clerk/clerk-react";

const OrganizationSettings = () => {
  const { organization } = useOrganization();
  return (
    <>{organization ? <OrganizationProfile /> : <OrganizationSwitcher />}</>
  );
};

export default OrganizationSettings;
