import { Contact } from "../contacts/type";
import { Facility } from "../facilities/type";
import { SchedulingMethod } from "../scheduling-methods/type";
import { Shipment } from "../shipments/types";
import { TaskUpdate } from "../task-updates/types";

export enum TaskStatus {
  SUGGESTED = "suggested",
  IN_PROGRESS = "in_progress",
  ESCALATED = "escalated",
  AWAITING_SYNC = "awaiting_sync",
  COMPLETED = "completed",
  CLOSED = "closed_outside_hubflow",
  NO_ACTION = "no_action"
};

export enum TaskType {
  SCHEDULING = "scheduling",
  EMAIL = "email",
  PRESET = "preset",
};

export enum StopType {
  PICK_UP = "pick_up",
  DROP_OFF = "drop_off",
};

export interface TaskContact extends Contact {
  taskId?: string;
};

export interface Task {
  id?: string;
  seq: number;
  shipmentId: string;
  createdAt?: Date;
  status: TaskStatus;
  taskType: TaskType;
  stopType: StopType;
  scheduledAt: Date;
  earlyScheduledAt: Date;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  timezone: string;
  locationName: string;
  locationNotes: string;
  confirmNum: string;
  facilityId: string;
  poNum: string[];
  equipmentType: string;
  shipment: Shipment;
  schedulingMethod: SchedulingMethod;
  contacts: TaskContact[];
  taskUpdates: TaskUpdate[];
  facility: Facility;
};