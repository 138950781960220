import useRailsToast from "@/components/utils/use-rails-toast";
import { useAuth } from "@clerk/clerk-react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Task, TaskStatus } from "../../types";
import { AppointmentInputType } from "./type";
import { getDayOfWeek, getScheduledDateTime, secondsToTime, setScheduledDateTime } from "@/components/utils/date-time-utils";
import { isFCFS } from "@/components/utils/sched-method-utils";

const AppointmentInput = ({ task, type, disabled, onUpdate, className } :
  { task: Task, type: AppointmentInputType, disabled?: boolean,
    onUpdate?: () => void, className?: string }) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const isDate = type === AppointmentInputType.DATE;
  const fieldName = type === AppointmentInputType.EARLY_TIME ? 'earlyScheduledAt' : 'scheduledAt';
  const openHours = task.facility?.openingDays?.[new Date(task.scheduledAt)
    .toLocaleString('us', { weekday: 'short', timeZone: task.timezone }).toLowerCase()];

  const getApptTime = () => {
    if (type === AppointmentInputType.EARLY_TIME && !task.earlyScheduledAt) {
      return secondsToTime(openHours?.min || 28800);
    } else {
      return getScheduledDateTime(task[fieldName] || task.scheduledAt, task.timezone, isDate);
    }
  };

  const [taskTime, setTaskTime] = useState(getApptTime());
  let minTime: string = secondsToTime(openHours?.min), maxTime: string = secondsToTime(openHours?.max);

  if (task.status === TaskStatus.NO_ACTION) {
    disabled = true;
  } else if (isFCFS(task.schedulingMethod)) {
    if (type === AppointmentInputType.EARLY_TIME) {
      maxTime = getScheduledDateTime(task.scheduledAt, task.timezone, false);
    } else if (type === AppointmentInputType.TIME && task.earlyScheduledAt) {
      minTime = getScheduledDateTime(task.earlyScheduledAt, task.timezone, false);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateTaskTime(e);
    }
  };

  const updateTaskTime = (e) => {
    const value = e.target.value;
    if (e.target.checkValidity()) {
      let newDate = setScheduledDateTime(task[fieldName] || task.scheduledAt, task.timezone, value, isDate);
      if (!task[fieldName] || task[fieldName].getTime() != newDate.getTime()) {
        task[fieldName] = newDate;
        updateTask(task);
      }
      e.target.form.classList.remove('was-validated');
    } else {
      e.target.form.classList.add('was-validated');
    }
    return value;
  };

  const updateTask = async (task) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { id: task.id, [fieldName]: task[fieldName] } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if ([200].includes(response.status)) {
      railsToast({ info: "Task has been updated!" }, 200);
      task.taskUpdates.push(...await response.json());
      onUpdate();
    }
    else
      railsToast(await response.json(), response.status);
    
  };

  return (
    <Form className={className}>
      <Form.Control type={isDate ? "date" : "time"} required disabled={disabled}
        min={isDate ? `${new Date().getFullYear() - 20}-01-01` : minTime }
        max={isDate ? `${new Date().getFullYear() + 50}-12-31` : maxTime }
        style={isDate ? { width: '155px'} : null } value={taskTime}
        onChange={e => setTaskTime(e.target.value)}
        onBlur={updateTaskTime} onKeyDown={handleKeyDown} />
    </Form>
  )
}

export default AppointmentInput;