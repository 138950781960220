import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { FacilityNote } from "../../type";
import ContactsInput from "@/components/contacts/input/contacts_input";
import { detectContactType } from "@/components/utils/contact-utils";
import { Contact } from "@/components/contacts/type";

const FacilityContactsInput = ({ facilityNote, onUpdate, showAll = true } :
  {facilityNote: FacilityNote, onUpdate?: () => void, showAll?: boolean }) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();

  const addContact = async (contact: string) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/facility_contacts`, {
      method: "POST",
      body: JSON.stringify({ contact: { facilityNoteId: facilityNote.id,
        contact: contact, contactType: detectContactType(contact) } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();
    if (response.status === 201) {
      railsToast({ info: "Contact has been added!" }, response.status);
      return json;
    } else {
      railsToast(json, response.status);
      return null;
    }
  }

  const updateContact = async (contact: Contact) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/facility_contacts/${contact.id}`, {
      method: "PUT",
      body: JSON.stringify({ contact: { contact: contact.contact } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();
    if (response.status === 200) {
      railsToast({ info: "Contact has been updated!" }, response.status);
      return true;
    } else {
      railsToast(json, response.status);
      return false;
    }
  }

  const removeContact = async (contactId: string) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/facility_contacts/${contactId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      railsToast({ info: "Contact has been deleted!" }, 404);
      return true;
    } else {
      railsToast(await response.json(), response.status);
      return false;
    }
  };

  return (<ContactsInput schedMethod={facilityNote.schedulingMethod}
    contacts={facilityNote.contacts} showAll={showAll}
    onAdd={addContact} onModify={updateContact}
    onRemove={removeContact} onUpdate={onUpdate} />);
};

export default FacilityContactsInput;