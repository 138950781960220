import { Task } from "../tasks/types";
import { getShortDateTime, timezones } from "./date-time-utils";

const ZENGISTICSID = "org_2mFuk9StgND9smb2OZG7ShRhGta";

export const generateSubject = (task: Task, orgId: String) => {
  let zengisticsSubject = `Delivery Request`;
  let genericSubject = `Walmart Pickup Request / ID# ${task.shipment.bolNum}`;
  if (task.poNum?.length > 0) {
    genericSubject += ` / PO# ${task.poNum[0]}`;
    zengisticsSubject += ` - ${task.poNum[0]}`;
  }
  genericSubject += ` / PRO# ${task.shipment?.shipmentId}`;

  if (orgId == ZENGISTICSID) {
    return zengisticsSubject;
  } else {
    return genericSubject;
  }
};

export const generateMessage = (task: Task, orgId: String) => {
  const scheduledAt = `${getShortDateTime(task.scheduledAt, task.timezone)} ${
    timezones[task.timezone]
  }`;
  let zengisticsMessage = `Hello,\n\nMay we please schedule the following delivery appointment.\n\n10/10`;
  let genericMessage = `Hello,\n\nCould we schedule a pickup appointment for the below listed Walmart PO numbers for ${scheduledAt} or around that time?`;

  if (task.poNum?.length > 1) {
    genericMessage += `\n\nPO's:\n${task.poNum.join("\n")}`;
    zengisticsMessage += `PO's: ${task.poNum.join(",")}`;
  } else if (task.poNum?.length > 0) {
    zengisticsMessage += `PO#: ${task.poNum[0]} - `;
  }

  genericMessage += `\n\nThank you,`;
  zengisticsMessage += "\n\nThank you";

  if (orgId == ZENGISTICSID) {
    return zengisticsMessage;
  } else {
    return genericMessage;
  }
};

export const listNotes = (notes: string) => {
  return notes?.startsWith("[")
    ? JSON.parse(notes)
        .flat()
        .filter((note) => note)
    : [notes];
};

export const isPortalUpdate = (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
}