import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { Task, TaskStatus } from "@/components/tasks/types";
import { SchedulingMethod } from "@/components/scheduling-methods/type";
import ContactsInput from "@/components/contacts/input/contacts_input";
import { detectContactType } from "@/components/utils/contact-utils";
import { Contact } from "@/components/contacts/type";

const TaskContactsInput = ({ task, disabled, onUpdate, showAll = true } :
  { task: Task, disabled?: boolean, onUpdate?: () => void, showAll?: boolean }) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  
  if (task.status !== TaskStatus.SUGGESTED || task.schedulingMethod === SchedulingMethod.FCFS)
    disabled = true;

  const addContact = async (contact: string) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/contacts`, {
      method: "POST",
      body: JSON.stringify({ contact: { taskId: task.id, contact: contact,
        contactType: detectContactType(contact) } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();
    if (response.status === 201) {
      railsToast({ info: "Contact has been added!" }, response.status);
      if (task.taskUpdates)
        task.taskUpdates.push(json.taskUpdate);
      return json.contact;
    } else {
      railsToast(json, response.status);
      return null;
    }
  }

  const updateContact = async (contact: Contact) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/contacts/${contact.id}`, {
      method: "PUT",
      body: JSON.stringify({ contact: { contact: contact.contact } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();
    if (response.status === 200) {
      railsToast({ info: "Contact has been updated!" }, response.status);
      if (task.taskUpdates)
        task.taskUpdates.push(json.taskUpdate);
      return true;
    } else {
      railsToast(json, response.status);
      return false;
    }
  }

  const removeContact = async (contactId: string) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/contacts/${contactId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      railsToast({ info: "Contact has been deleted!" }, 404);
      if (task.taskUpdates)
        task.taskUpdates.push(await response.json());
      return true;
    } else {
      railsToast(await response.json(), response.status);
      return false;
    }
  };

  return (<ContactsInput schedMethod={task.schedulingMethod}
    contacts={task.contacts} showAll={showAll} disabled={disabled}
    onAdd={addContact} onModify={updateContact}
    onRemove={removeContact} onUpdate={onUpdate} />);
};

export default TaskContactsInput;