export enum ContactType {
  PORTAL = "portal",
  EMAIL = "email",
  PHONE = "phone",
};

export interface Contact {
  id?: string;
  contact: string;
  contactType: ContactType;
};

export interface Portal {
  name: string;
  url: string;
  username: string;
  password: string;
  securityQuestion: string;
  notes: string;
};