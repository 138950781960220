import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Col, Form, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { Facility, FacilityNote, OpeningDays } from "../type";
import TaskStatusPill from "@/components/tasks/show/task-status-pill";
import { listNotes } from "@/components/utils/task-utils";
import FacilityContactsInput from "./contacts/facility_contacts_input";
import SchedulingMethodInput from "@/components/scheduling-methods/input/scheduling_method_input";
import { SchedulingMethod } from "@/components/scheduling-methods/type";
import { acceptableContactType } from "@/components/utils/contact-utils";
import TaskContactsInput from "@/components/tasks/show/contacts/task_contacts_input";
import { secondsToTime } from "@/components/utils/date-time-utils";

const FacilityShow = () => {
  const { getToken } = useAuth();
  const { facilityId } = useParams();
  const railsToast = useRailsToast();
  const [facility, setFacility] = useState<Facility>();
  const [hardToSchedule, setHardToSchedule] = useState<boolean>(false);
  const [includedToHubflow, setIncludedToHubflow] = useState<boolean>(false);
  const [openDays, setOpenDays] = useState<OpeningDays>();
  const [loading, setLoading] = useState(true);
  const [updateKey, setUpdateKey] = useState<number>(0);

  const fetchFacility = async () => {
    const accessToken = await getToken();
    const response = await fetch(
      `/api/v1/facilities/${facilityId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setFacility({...json,
        facilityNotes: json.facilityNotes.map(fn => ({...fn, facilityId: json.id})),
        tasks: json.tasks.map(task => ({...task, scheduledAt: new Date(task.scheduledAt) }))});
      setHardToSchedule(json.facilityNotes?.[0]?.hardToSchedule);
      setIncludedToHubflow(json.facilityNotes?.[0]?.includedToHubflow);
      const openingDays = json.openingDays;
      for (const day of Object.keys(openingDays)) {
        if (openingDays[day]) {
          openingDays[day].from = secondsToTime(openingDays[day].min);
          openingDays[day].to = secondsToTime(openingDays[day].max);
        }
      }
      setOpenDays(openingDays);
    } else {
      railsToast({ info: json.error }, status);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFacility();
  }, []);

  useEffect(() => {
    if (
      facility && (
        (hardToSchedule !== facility.facilityNotes?.[0]?.hardToSchedule) ||
        (includedToHubflow !== facility.facilityNotes?.[0]?.includedToHubflow)
      )) {
      updateFacility(true);
    }
  }, [hardToSchedule, includedToHubflow]);

  const validDays = () => {
    for (const day of Object.keys(openDays)) {
      if (openDays[day]) {
        return !(openDays[day].min === undefined || openDays[day].man === undefined);
      }
    }
    return true;
};

  useEffect(() => {
    if (facility && facility.openingDays !== openDays && validDays) {
      updateFacility(false);
    }
  }, [openDays]);

  const handleValidation = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      updateFacility(false);
      e.target.form.classList.remove('was-validated');
    } else {
      e.target.form.classList.add('was-validated');
    }
  };

  const setOpenDay = (e) => {
    setOpenDays(e.target.checked ? {...openDays,
      [e.target.value]: { min: 28800, from: '08:00', max: 57600, to: '16:00' } } :
      {...openDays, [e.target.value]: null });
  }

  const updateFacility = async (notes_only: boolean) => {
    const accessToken = await getToken();
    const response = await fetch(
      `/api/v1/facilities/${facility.id}`,
      {
        method: "PUT",
        body: JSON.stringify(notes_only ? {
          facilityNote: { hardToSchedule: hardToSchedule, includedToHubflow: includedToHubflow } } : {
          facility: { openingDays: openDays}
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const status = response.status;
    if ([200, 304].includes(status)) {
      facility.facilityNotes[0].hardToSchedule = hardToSchedule;
      facility.facilityNotes[0].includedToHubflow = includedToHubflow;
      setFacility({...facility});
      railsToast({ info: 'Facility has been updated' }, status);
    } else {
      railsToast({ info: (await response.json()).error }, status);
    }
    setLoading(false);  
  };

  const timeToSeconds = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60;
  };

  const setMinHours = (e) => {
    setOpenDays({...openDays,
      [e.target.formTarget]: {...openDays[e.target.formTarget],
        min: timeToSeconds(e.target.value), from: e.target.value} });
  };

  const setMaxHours = (e) => {
    setOpenDays({...openDays,
      [e.target.formTarget]: {...openDays[e.target.formTarget],
        max: timeToSeconds(e.target.value), to: e.target.value} });
  };

  const updateSchedMethod = async (facilityNote: FacilityNote, schedMethod: SchedulingMethod) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/facilities/${facilityNote.facilityId}`, {
      method: "PUT",
      body: JSON.stringify({ facilityNote: { schedulingMethod: schedMethod } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    if ([200].includes(status)) {
      railsToast({ info: "Facility scheduling method has been updated!" }, status);
      if (acceptableContactType(facilityNote.schedulingMethod)
         !== acceptableContactType(schedMethod)) {
        facilityNote.contacts = [];
      }
      facilityNote.schedulingMethod = schedMethod;
      setUpdateKey(updateKey+1)
      return true;
    } else {
      railsToast(await response.json(), status);
      return false;
    }
  };

  if (loading) {
    return (
    <div className="text-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    );
  }
  if (facility)
  return (
    <>
      <h1 className="h2">{facility.name}</h1>
      <div className="border-bottom my-3"></div>
      <Row className="mt-4 mb-2">
        <Col xs={12} md={6}>
          <div className="fw-bold">{facility.address}</div>
          <div className="fw-bold">{facility.city}, {facility.state}, {facility.zipCode}</div>
        </Col>
        <Col xs={12} md={6}>
          <div className="fw-bold mb-2">Contacts</div>
          <Stack direction="horizontal" gap={2}>
            <SchedulingMethodInput schedMethod={facility.facilityNotes?.[0]?.schedulingMethod}
              contacts={facility.facilityNotes?.[0]?.contacts}
              onUpdate={async (sm) => { return await updateSchedMethod(facility.facilityNotes?.[0], sm); }} />
            <FacilityContactsInput key={updateKey} facilityNote={facility.facilityNotes?.[0]} />
          </Stack>
        </Col>
      </Row>
      <Row className="mt-4 mb-2">
        <Col xs={12} md={6}>
          <div>
            <div className="fw-bold">Open Hours</div>
            <Form className="mt-2">
              <div className="fw-bold mt-3">Open Days</div>
              <div className="ms-3">
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <Form.Check type="switch" checked={!!openDays.mon}
                    value="mon" onChange={setOpenDay}/>
                  <span>Monday</span>
                  From:
                  <Form.Control type="time" required
                    min="00:00" max={openDays.mon?.to} value={openDays.mon?.from}
                    formTarget="mon" onChange={setMinHours} onBlur={handleValidation} />
                  To:
                  <Form.Control type="time" required
                    min={openDays.mon?.from} max="23:59" value={openDays.mon?.to}
                    formTarget="mon" onChange={setMaxHours} onBlur={handleValidation} />
                </Stack>
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <Form.Check type="switch" checked={!!openDays.tue}
                    value="tue" onChange={setOpenDay}/>
                  <span>Tuesday</span>
                  From:
                  <Form.Control type="time" required
                    min="00:00" max={openDays.tue?.to} value={openDays.tue?.from}
                    formTarget="tue" onChange={setMinHours} onBlur={handleValidation} />
                  To:
                  <Form.Control type="time" required
                    min={openDays.tue?.from} max="23:59" value={openDays.tue?.to}
                    formTarget="tue" onChange={setMaxHours} onBlur={handleValidation} />
                </Stack>
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <Form.Check type="switch" checked={!!openDays.wed}
                    value="wed" onChange={setOpenDay}/>
                  <span>Wednesday</span>
                  From:
                  <Form.Control type="time" required
                    min="00:00" max={openDays.wed?.to} value={openDays.wed?.from}
                    formTarget="wed" onChange={setMinHours} onBlur={handleValidation} />
                  To:
                  <Form.Control type="time" required
                    min={openDays.wed?.from} max="23:59" value={openDays.wed?.to}
                    formTarget="wed" onChange={setMaxHours} onBlur={handleValidation} />
                </Stack>
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <Form.Check type="switch" checked={!!openDays.thu}
                    value="thu" onChange={setOpenDay}/>
                  <span>Thursday</span>
                  From:
                  <Form.Control type="time" required
                    min="00:00" max={openDays.thu?.to} value={openDays.thu?.from}
                    formTarget="thu" onChange={setMinHours} onBlur={handleValidation} />
                  To:
                  <Form.Control type="time" required
                    min={openDays.thu?.from} max="23:59" value={openDays.thu?.to}
                    formTarget="thu" onChange={setMaxHours} onBlur={handleValidation} />
                </Stack>
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <Form.Check type="switch" checked={!!openDays.fri}
                    value="fri" onChange={setOpenDay}/>
                  <span>Friday</span>
                  From:
                  <Form.Control type="time" required
                    min="00:00" max={openDays.fri?.to} value={openDays.fri?.from}
                    formTarget="fri" onChange={setMinHours} onBlur={handleValidation} />
                  To:
                  <Form.Control type="time" required
                    min={openDays.fri?.from} max="23:59" value={openDays.fri?.to}
                    formTarget="fri" onChange={setMaxHours} onBlur={handleValidation} />
                </Stack>
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <Form.Check type="switch" checked={!!openDays.sat}
                    value="sat" onChange={setOpenDay}/>
                  <span>Saturday</span>
                  From:
                  <Form.Control type="time" required
                    min="00:00" max={openDays.sat?.to} value={openDays.sat?.from}
                    formTarget="sat" onChange={setMinHours} onBlur={handleValidation} />
                  To:
                  <Form.Control type="time" required
                    min={openDays.sat?.from} max="23:59" value={openDays.sat?.to}
                    formTarget="sat" onChange={setMaxHours} onBlur={handleValidation} />
                </Stack>
                <Stack direction="horizontal" gap={2} className="mt-3">
                  <Form.Check type="switch" checked={!!openDays.sun}
                    value="sun" onChange={setOpenDay}/>
                  <span>Sunday</span>
                  From:
                  <Form.Control type="time" required
                    min="00:00" max={openDays.sun?.to} value={openDays.sun?.from}
                    formTarget="sun" onChange={setMinHours} onBlur={handleValidation} />
                  To:
                  <Form.Control type="time" required
                    min={openDays.sun?.from} max="23:59" value={openDays.sun?.to}
                    formTarget="sun" onChange={setMaxHours} onBlur={handleValidation} />
                </Stack>
              </div>
            </Form>
          </div>
          <Stack direction="horizontal" gap={2} className="mt-3">
            <span className="fw-bold">Hard to schedule</span>
            <Form.Check type="switch" checked={hardToSchedule}
              onChange={e => setHardToSchedule(e.target.checked)} />
          </Stack>
          <Stack direction="horizontal" gap={2} className="mt-3">
            <span className="fw-bold">Included in Hubflow</span>
            <Form.Check type="switch" checked={includedToHubflow}
              onChange={e => setIncludedToHubflow(e.target.checked)} />
          </Stack>
        </Col>
        <Col xs={12} md={6}>
          <div className="fw-bold">Notes</div>
          <ul>
            {listNotes(facility.facilityNotes?.[0]?.notes).map(note => (<li>{note}</li>))}
          </ul>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Appointments</th>
            <th>Date</th>
            <th>Contacts</th>
            <th>Scheduling Method</th>
            <th>Shipment</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {facility.tasks.map(task => (
            <tr className="align-middle">
              <td>
                <Stack direction="horizontal" gap={2} className="text-capitalize">
                  <a href={`/tasks/${task.id}`}>{task.stopType.replace('_', ' ')}</a>
                </Stack>
              </td>
              <td>{task.scheduledAt.toLocaleDateString('us', { timeZone: task.timezone })}</td>
              <td><TaskContactsInput task={task} disabled={true} showAll={false} /></td>
              <td className="text-capitalize">{task.schedulingMethod}</td>
              <td><a href={`/shipments/${task.shipmentId}`}>{task.shipment?.shipmentId}</a></td>
              <td><TaskStatusPill status={task.status} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export default FacilityShow;
